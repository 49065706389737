@media screen and (max-width: 991px) {

}
.paddingRemoveVertical-draw {
    padding-top: 10px !important;
    padding-bottom: 0px !important;
}

.internal-transportation-btndiv {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.customIngredientNameView{
    margin-bottom: 0px !important;
}

.customMainViewIngredientSearch{
    margin-bottom: 15px !important;
}

.custom-dimmer-modal-simulation {
    left: 6% !important;
    margin: 0px !important;
    width: 94% !important;
    background: #ffffff !important;
    padding-bottom: 20px !important;
    overflow: hidden;
}