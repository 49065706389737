.imageView {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(5, 5vw);
  grid-gap: 15px;
}

.imageViewMain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px !important;
}
.imageViewContainer {
  grid-column-start: 1;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 8;
}

.customItemView {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7e8e3;
  border-radius: 10px;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 11px 12px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #327ab7;
  color: #ccc;
  font-weight: 500;
  font-size: 18px;
}

.file-preview {
  margin: 0 10px;
}
