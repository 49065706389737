.ingredientDetailButtonView {
  margin-top: 15px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  display: flex !important;
  justify-content: flex-end;
  padding: 1rem;
}

.cellWidth {
  width: 150px !important;
}

.dataBox {
  margin: 1rem 0.1rem !important;
  border: 1px solid;
  border-color: #d2d7e0;
  padding: 1rem !important;
  background-color: #fcfcfc;
}

.climateImpactMessage {
  background-color: #dbecca !important;
  font-family: "ProximaNova";
  font-weight: 700;
  font-size: 16px !important;
  line-height: 10px;
  color: #267c38 !important;
  text-align: center;
  border: 1px solid #267c38 !important;
}

.climateImpactValue {
  font-weight: 400;
  font-size: 16px;
  color: #267c38 !important;
}
