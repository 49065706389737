.productDetailsViewMain {
  background: #fff !important;
  //    padding: 1rem !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.productSummaryDetailsInsert {
  margin: 30px 15px;
}

.productDetailsButtonView {
  margin-top: 15px;
}
