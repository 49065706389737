.progressBarViewMain {
  .ui.progress {
    margin: 5px;
    border-radius: 25px !important;
    background-color: var(--pecentageSub);
  }
  .ui.progress .bar {
    border-radius: 25px !important;
    height: 35px !important;
    background-color: var(--mainColor);
  }
}

.progressBarTitle {
  margin-top: 15px !important;
  margin-bottom: 1px !important;
  margin-left: 5px;
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 700;
  font-size: 10;
}

.progressImageViewMain {
  position: absolute;
  z-index: 100;
  margin-top: 6px;
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.progressImageView {
  width: 22px;
  filter: brightness(0) invert(1);
}

.progressCount {
  margin-top: 2px;
  margin-left: 10px;
  color: #fff;
  font-weight: 400;
}

.progressCountView {
  margin-top: 3px !important;
  margin-left: 10px;
  color: #fff;
  font-weight: 400;
}
