.wasteManagementDetailsInsertMain{
    display: flex;
    align-items: center !important;
  }
  
  .wasteManagementDetailsInsertBox{
    border:1px solid  var(--mainColor);
    width: 98%;
    height:auto;
    padding: 10px;
    border-radius: 5px;
  }
  
  .wasteManagementTitle{
    color: var(--mainColor);
    margin-bottom: 5px !important;
  }
  
  .wasteManagementCustomItemAdderGridWithInputs {
    border: 0px !important;
    margin-top: 0px !important;
  }
  
  .wasteManagementCustomItemAddButtonMain {
    margin-top: 15px !important;
  }
  
  .wasteManagementCustomItemAdderGridMain {
    margin-top: 15px !important;
    margin-bottom: 0px !important;
  }
  
  .alignTableIcon{
    text-align: center !important;
  }
  
  .alignPlusButton{
    display: flex !important;
    align-items: center !important;
  }

  .wasteManagementAdd{
    display: flex !important;
    align-items: center !important;
  }