.itemAdderGridMain {
  align-items: center !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  // padding: 10px;
}

.itemAdderGridMainTitle {
  align-items: center !important;
  margin-top: 20px !important;
  // padding: 10px;
}

.adder {
  width: 100% !important;
}

.itemAdderGridWithInputs {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--mainColor);
  border-radius: 5px;
  padding: 0px 0px 0px 10px !important;
}

.itemAdderGridWithInputsTitle {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  padding: 0px 0px 0px 10px !important;
}

.itemAdderGridWithBtns {
  display: flex;
  padding: 5px;
  width: 3%;
}

.itemButtonAddMain {
  display: grid !important;
  justify-content: flex-start !important;
}

.itemButtonMainView {
  display: grid !important;
  justify-content: flex-start !important;
  margin-top: 10px !important;
}

.btnCustom {
  margin-top: -20px;
}

.btnCustom0th {
  margin-top: 5px;
}

@media screen and (max-width: 991px) {
  .itemButtonMainView {
    display: flex !important;
    flex-direction: row;
    margin-top: 15px;
  }
}
