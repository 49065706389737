.productTitle {
  font-family: "ProximaNova";
  font-size: 20px;
  margin-bottom: 10px;
  color: #707070;
  font-weight: 600 !important;
}

.packagingMethodDetailsView{
  margin-top: 10px !important;
  padding-bottom: 30px !important;
}

.packagingMainView{
  margin-bottom: 15px !important;
}

.productEditViewMain {
  background: #fff !important;
  //    padding: 1rem !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}

.productEditInsert {
  margin: 15px;
}

.packagingDetailsView{
  margin-bottom: 20px;
}

.contentMargin {
  margin-bottom: 10px !important;
  padding-right: 15px !important;
}

.productEditButtonView {
  margin-top: 15px;
}

.productEditUnitMain {
  margin-top: 30px !important ;
  padding-top: 15px !important;
}

.productEditButtonAdd {
  margin-bottom: 15px !important;
}

.productEditButtonUpdate {
  margin-top: 30px !important;
  text-align: end;
}

.productEditImageUpload {
  margin-top: 12px;
}

.customGridColomnType {
  margin-top: 5px !important;
}

.customViewTypeMainView {
  margin-top: 10px !important;
}
