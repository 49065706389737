.table-footer {
  display: flex;
  justify-content: space-between;

  .pagination {
    border: none !important;
    box-shadow: none !important;
  }

  a {
    color: #9FA2B4 !important;
    font-family: 'Proxima Nova', sans-serif;
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 12px !important;
    text-align: center !important;
  }

  .item {
    position: unset !important;
  }

  .active {
    color: #FFFFFF !important;
    background: #C9DDBD !important;
  }
}