.customTableBottom{
    margin-bottom: 25px !important;
}

.unitTypeView{
    margin-left: 5px;
}

.viewCurrentFacilityTable{
   
}

.recipeTableViewMain{
    margin-bottom: 30px !important;
}