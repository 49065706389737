.inputTextContanier {
    margin-bottom: 10px !important;
    margin-top: 5px;
    .ui[class*="left icon"].input > input {
      font-family: "ProximaNova" !important;
      font-size: 14px !important;
      font-weight: 600 !important;
      background: #fff;
      color: var(--mainTextInptColor) !important;
    }
  }

  .inputTextLable {
    font-family: "ProximaNova";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--mainTextInputHeader);
  }

  .textInputError {
    font-family: "ProximaNova" !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #ef576a !important;
    margin-top: 5px;
  }
  
  .react-datepicker__input-container {
    input{
        font-family: "ProximaNova" !important;
        margin: 0;
        outline: 0;
        -webkit-appearance: none;
        line-height: 1.21428571em;
        padding: 0.67857143em 1em;
        font-size: 15px !important;
        font-weight: 600 !important;
        background: #ffffff !important;
        border: 1px solid var(--mainTextInputBorderColor) !important;
        color: var(--mainTextInptColor) !important;
        border-radius: 0.28571429rem;
        box-shadow: 0 0 0 0 transparent inset;
        transition: color 0.1s ease, border-color 0.1s ease;
        width: 100%;
    }

}