.menuBarMain {
  margin-top: 15px;
  display: flex;
  // min-height: 100vh;
  flex-direction: column;
  max-width: 2080px;
  margin-left: auto;
  margin-right: auto;
  //   background-color:#84A756;
  border-radius: 5px;
}

.mainBarTitle {
  font-size: 18px;
  margin-bottom: 10px !important;
  color: #fff;
  font-weight: 500;
}

.mainBarView {
  margin-bottom: 20px;
  cursor: pointer;
}

.subBarTitle {
  font-size: 16px;
  margin-bottom: 5px !important;
  color: #fff;
  font-weight: 500;
  margin-left: 12px;
}

.subBarMainWrapper {
  display: flex;
  margin: 10px;
}

.sideBarIconView {
  flex: 0.32;
}

.subMenuActive {
  color: rgb(0, 0, 0);
}

.subMenuDefault {
  color: white;
  text-align: left;
}
.sideBarIcon {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.sideBarIconDefault {
  margin-top: 45px;
  display: flex;
  align-items: center;
}

.logoutButtonView {
  display: flex;
  align-items: center;
}

.sideBarIconText {
  flex: 1;
}

.mainMenuView {
  overflow: hidden;
}

.menuTitleView {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: var(--mainTextInptColor);
}

.menuTitleViewSelect {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: var(--mainColor);
}

.menuBarActiveColor{
  filter: drop-shadow(0px 1000px 0 var(--menuBarIconActive));
  transform: translateY(-1000px);
}

.menuBarIconView{
  filter: drop-shadow(0px 1000px 0 var(--menuBarIconDeactive));
  transform: translateY(-1000px);
}

.customerMenuIcon{
  position: absolute;
  left: 0 !important;
  max-width: 400px !important;
  padding: 15px !important;
}