.node-item {
    align-items: center !important;
    padding: 0px !important;
    margin: 5px !important;
    border-radius: 30px;
    background: none;
}


.node-item-image {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: var(--mappingImageBackground);
    display: grid;
    place-items: center;
}

.node-card-image img {
    width: 25px;
    height: 25px;
    filter: sepia(var(--drawingIconsSepia)) hue-rotate(var(--drawingIconsHueRotate)) saturate(200%) !important;
}


.node-header {
    content: '';
    position: absolute;
    text-wrap: nowrap;
    top: -35px;
    left: 50%;
    transform: translate(-50%, 0);
    background: var(--mappingTitleViewBackground);
    z-index: 1000;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.445);
    padding: 5px !important;
    border-radius: 24px;
    opacity: 0.6;
    cursor: grab;
    line-height: 1;
}

.node-header span {
    font-size: 14px;
    font-weight: 700;
    color: var(--mappingTitleText);
    line-height: 1;
}

.node-footer {
    display: none;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1000;
    padding: 5px !important;
    border-radius: 24px;
    cursor: grab;
    width: 100px;
    line-height: 1;
    text-align: center;
}

.node-footer span {
    font-size: 12px;
    font-weight: 400;
    color: var(--mappingBottomTitleText);
    line-height: 1;
}

.node-item:hover {
    .node-footer {
        display: block;
    }
}

.custom-handle {
    width: 100%;
    height: 100%;
    background: blue;
    position: absolute;
    margin: 15px;
    top: 0;
    left: 0;
    border-radius: 0;
    transform: none;
    border: none;
    opacity: 0;
}