.imageViewAvatar {
  margin-right: 0.25em;
  display: inline-block;
  width: 2em;
  height: 2em;
  border-radius: 500rem;
  align-items: center;
  
}

.imageViewIcon{
  filter: drop-shadow(0px 1000px 0 var(--menuBarIconDeactive)) !important;
  transform: translateY(-1000px) !important;
}