.product-drop-down-div {
    // position: absolute;
    z-index: 99;
    right: 0px;
    float: right;
    width: 500px;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    min-height: 50px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    background: var(--mappingSearch);
    margin-right: 20px;
    padding-right: 10px;
    padding-bottom: 8px;
}

.dropdown-title {
    display: flex;
    flex: 0.32;
    font-family: "ProximaNova";
    align-items: center !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 10px;
    color: var(--mainColor);
    padding-left: 10px;
    margin-top: 10px;
}

.action-grid {
    flex: 1;
    padding-top: 5px !important;
}

.action-grid-column {
    flex: 1;
}