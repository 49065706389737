.customRecipeButton {
  margin-right: 25px !important;
}

.processingCustomColumnStyle {
  margin-top: 5px !important;
}

.customCIPView {
  width: 100%;
}

.processButtonView {
  display: flex !important;
  width: 100%;
  justify-content: flex-end;
  margin-top: 50px;
}

.ingredientCardMain {
  background-color: #c0c5d4;
  padding: 10px;
  border-radius: 5px;
}

.customLabelText {
  display: block;
}

.ingredientCardMainSelect {
  background-color: #84a756;
  padding: 10px;
  border-radius: 5px;
}

.ingredientLabel {
  color: #ffffff !important;
  font-size: 15px !important;
}

.sipContentMain {
  align-items: center !important;
}

.sipContentName {
  font-family: "ProximaNova" !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  background: #fff;
  color: #707070;
}

.sipButtonContent {
  margin-top: 100px !important;
  margin-bottom: 25px !important;
  display: flex !important;
  width: 100% !important;
}

.sipContentButton {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  display: flex !important;
  width: 100% !important;
}

.sipCancel {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.sipAdd {
  display: flex;
  justify-content: flex-end;
}

.sipAddTotalError {
  display: flex;
  justify-content: flex-end;
  margin: 15px;
}

.sipContentTitle {
  font-family: "ProximaNova" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  background: #fff;
  color: #707070;
}

.sipPaddingBottom {
  padding-bottom: 0px !important;
}

.custom-tabs .item {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  background: #fff !important;
  color: var(--mainColor) !important;
  border: 1px solid var(--mainColor) !important;
}

.custom-tabs .active.item {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  background: var(--mainColor) !important;
  color: #fff !important;
  // border-left: #fff !important;
}

.custom-pointer .active.item:after {
  background: var(--mainColor) !important;
}

.customBtn {
  padding: 13px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  // margin-top: -1px !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customBtnCIP {
  padding: 13px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-top: 20px !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabViewMainActive {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  background: var(--mainColor) !important;
  color: #fff !important;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.newBack {
  margin: 10px;
  padding: 20px;
  border: 1px solid #a6a6a6;
  border-radius: 10px;
}

.tabViewMainInactive {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  background: #fff !important;
  color: var(--mainColor) !important;
  border: 1px solid var(--mainColor) !important;
}

.customProcessAdd {
  cursor: pointer;
}

.customAddProcessModal {
  margin: 0 !important;
  margin-top: -5px !important;
}

.chemicalDetailsInsertMain {
  display: flex;
  align-items: center !important;
}

.chemicalDetailsInsertBox {
  border: 1px solid var(--mainColor);
  width: 98%;
  height: auto;
  padding: 10px;
  border-radius: 5px;
}

.chemicalTitle {
  color: var(--mainColor);
  margin-bottom: 5px !important;
}

.chemicalCustomItemAdderGridWithInputs {
  border: 0px !important;
  margin-top: 0px !important;
}

.chemicalCustomItemAddButtonMain {
  margin-top: 15px !important;
}

.chemicalCustomItemAdderGridMain {
  margin-top: 15px !important;
  margin-bottom: 0px !important;
}

.alignTableIcon {
  text-align: center !important;
}

.alignPlusButton {
  display: flex !important;
  align-items: center !important;
}

.heatSourceTable {
  margin-bottom: -5px !important;
}

.plusButton {
  margin-top: 1.5rem;
}