.tabSelectTitle {
  font-family: "ProximaNova";
  color: var(--mainColor) !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  cursor: default !important;
  margin: auto 0 !important;
  text-align: center;
  // border-bottom: 2px solid;
  padding-bottom: 5px;
}

.tabTitle {
  font-family: "ProximaNova";
  color: var(--mainColor) !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  cursor: default !important;
  margin: auto 0 !important;
  text-align: center;
  padding-bottom: 5px;
}

.tabContent {
  margin-top: 30px !important;
  margin-bottom: 50px !important;
  border-radius: 5px;
}

.tabSelectTab {
  font-family: "ProximaNova";
  color: rgb(105, 174, 53) !important;
  font-weight: 500;
  font-size: 15px;
  cursor: default !important;
  display: flex !important;
  align-content: center !important;
  // padding: 24px 4px 24px 41px;
  padding-bottom: 10px !important;
  // background-color:#FFFFFF;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  // border-radius: 3px;
  justify-content: center;
  border: 1px solid var(--mainColor);
  border-bottom: none;
}

.tabMain {
  // width: 100%;
  width: 100% !important;
  padding-right: 0;
  margin-right: 0;
  // margin-top: 10px !important;
  margin-bottom: 20px !important;
  // margin-top: 30px !important;
}

@media screen and (min-width: 991px) {
  .tabMain {
    width: 100% !important;
    padding-right: 0 !important;
    margin-right: 0 !important;
    height: 64px;
  }
}

@media screen and (min-width: 991px) {
  .tabSelectOther {
    display: flex !important;
    align-content: center !important;
    padding-bottom: 10px !important;
    justify-content: center !important;
    border-bottom: 1px solid var(--mainColor);
    background-color: #f9f9f4;
  }
  .tabSelectTitle {
    width: fit-content;
  }
  .tabTitle {
    width: fit-content;
  }
}

@media screen and (max-width: 991px) {
  .tabSelectOther {
    display: flex !important;
    align-content: center !important;
    padding-bottom: 10px !important;
    border-bottom: 1px solid lightgray;
  }

  .tabMain {
    border-bottom: 0px solid lightgray;
  }
  .tabSelectTitle {
    width: 100% !important;
  }
  .tabTitle {
    width: 100%;
  }
  .tabSelectTab {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
