.tbleHeader {
  background-color: var(--tableHeaderColor) !important;
  color: var(--tableHeaderText) !important;
}

.tbleHeaderSimulate {
  background-color: #D2D7E0 !important;
  color: #535D6D !important;
}

.tbleR {
  background-color: #fff;
  color: var(--mainTextInptColor);
  border-color: var(--mainTextInptColor);
}

.tbl {
  border: red;
}

.iconPM {
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  margin-left: 10px !important;
  border: none;
  display: inline-block;
  animation: blink 2s ease-in infinite;
}

@keyframes blink {

  from,
  to {
    opacity: 1
  }

  50% {
    opacity: 0
  }
}