.mainCont {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  height: 36px;
  background: #fff8ec;
  border-radius: 4px;
  margin-bottom: -20px;
  margin-top: -20px;
}

.textP {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--mainTextInptColor);
}
