.packagingSimulationButtonContainer {
  display: none !important;
}

.impact-flow {
  padding-top: 0px;
}

.impact-flow-content {
  height: 100%;
  border: 1px solid #71A950 !important;
}

.background-white {
  background: white;
  border-radius: .28571429rem;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
  background: #fff;
  margin: 1rem;
}

.table-icon {
  cursor: pointer;
}

.disable-button {
  padding: 15px 38px;
  margin-right: 10px;
  border-radius: 4px !important;
  pointer-events: none;
  opacity: 0.5;
  background-color: gray;
  border: none;
}

.border-none {
  border: none !important;
}
