.mainBottomView{
    display: flex !important;
    flex-direction: row;
    align-items: flex-end;
}
.modalBottomRight{
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
}

.modalBottomLeft{
    display: flex;
    flex: 1;
    flex-direction: row !important;
    justify-content: flex-end !important;
}