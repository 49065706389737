.product-title-container {
    width: 100% !important;
    display: flex !important;
    justify-content: flex-end !important;
  }

  .title-button {
    border: 0px !important;
  }

  .product-simulation-title{
    display: flex !important;
    width: max-content !important;
  }