.cultivarForm {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .custom-card {
        width: 100%;
        padding: 1rem;
        border-radius: 8px;

        .title {
            padding-left: 4px;
            color: var(--mainColor);
        }

        .list {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 8px;
        }
    }
    .buttonGroup {
        display: flex;
    }

    .plusButton {
        margin-top: 2rem;
    }

    .pesticideTable {
        margin-bottom: 1rem;
    }

    .error {
        color: red;
    }
}

.customDropDownGrid {
    margin-top: 10px !important;
  }

.empty-padding {
    padding: 0 !important;
}

.viewQuestion{
    display: flex !important;
    align-items: center;
}

.landUseView{
    display: flex !important;
    align-items: center;
}