.create-node-card {
    display: grid;
    grid-template-columns: 35px 200px;
    align-items: center !important;
    padding: 0px !important;
    margin-top: 10px !important;
    margin-bottom: 20px !important;
    margin-left: 25px !important;
    margin-right: 10px !important;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.445);
    border-radius: 2px;
    min-height: 48px;
    background: var(--mappingIconCard);
    cursor: grab;
}

.node-card-image {
    position: relative;
    left: -24px;
    height: 48px;
    border-radius: 24px;
}


.node-card-image img {
    width: 25px;
    height: 25px;
    filter: sepia(var(--drawingIconsSepia)) hue-rotate(var(--drawingIconsHueRotate)) saturate(200%) !important;
}

.card-details {
    display: grid;
    line-height: 1em !important;
    cursor: grab;
}

.card-header {
    font-size: 14px;
    font-weight: 700;
    color: var(--mappingIconTitleText);
    cursor: grab;
}

.card-extra {
    font-size: 12px;
    font-weight: 200;
    color: var(--mappingIconTitleSubText);
    cursor: grab;
}