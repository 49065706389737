.customREnegryButton {
  margin-right: 25px !important;
}

.enegryButtonView {
  display: flex !important;
}

.customItemAddButtonMainPacking {
  padding-top: 0px !important;
}

.enegryTitle {
  font-family: "ProximaNova";
  font-size: 20px;
  margin-bottom: 10px;
  color: #707070;
  font-weight: 600 !important;
}

@media screen and (min-width: 991px) {
  .customItemAdderGridWithInputsPacking {
    border: 0px !important;
  }

  .customItemAddButtonMainPacking {
    margin-top: 22px !important;
  }
}
