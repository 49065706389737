.custom-table {
  margin-top: 1em !important;
}

.custom-table thead th {
  background-color: #C9DDBD !important;
  color: #267C38 !important;
}

.custom-table tbody td {
  text-align: center;
}

.custom-table tbody tr td .custom-icon-button {
  margin: 0 0.2em;
}

.custom-table tfoot th {
  background: #FFFFFF !important;
}