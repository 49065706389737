.noteDocumentMainView {
    padding: 0px !important;
    margin-bottom: 0px !important
}

.customNoteButton {
    background-color: var(--noteDocumentButton);
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    position: relative;
    cursor: pointer;
    width: 100%;
    min-height: 50px !important;
}

.customNoteButtonOutLine {
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: var(--noteDocumentButton) !important;
    color: var(--noteDocumentButton);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    position: relative;
    font-weight: 700;
    cursor: pointer;
    min-height: 50px !important;
    width: 100%;
}

.customNoteButton::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 20px;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: var(--noteDocumentButton) transparent transparent transparent;
}

.customNoteImageView {
    padding: 15px 20px;
}

.customMoteDocumentText {
    height: 150px !important;
}

.noteDocumentSave{
    display: flex !important;
    justify-content: flex-end !important;
    padding-top: 0px !important;
}