.react_fast_diagram_Layer {
    width: 100% !important;
    height: 100% !important;
}

.drawing-wrapper {
    flex-grow: 1;
    height: 93% !important;
}

.customMainBottomContainer {
    padding: 0px !important;
}

.react-flow__attribution {
    font-size: 0px !important;
}

.open-side-menu-button {
    margin: 10px !important;
    background-color: var(--mappingSideOpenButton) !important;
}

.open-side-menu-button i {
    color: var(--mainColor) !important;
}

.product-empty-description {
    display: flex;
    justify-content: center;
    margin-top: 200px;
}

.drawing-wrapper .react-flow__handle {
    background: #be0b0b;
    box-shadow: 0px 0px 8px 0px rgba(208, 16, 16, 0.5);
}

$categories-map : (farmGate,
    processGate,
    ingredientGate,
);

@mixin categories() {
    @each $category in $categories-map {
        .react-flow__node-#{$category} {
            background: rgba(5, 18, 136, 0.1);
            border: 0px solid #fff;
            box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.5);
            resize: both;
            width: auto;
            z-index: -1 !important;
        }

        .react-flow__node-#{$category}.active {
            box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.9);
        }

        .react-flow__node-#{$category}.hover {
            box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.628);
        }

        .react-flow__node-#{$category}.selected {
            border-width: 1px;
            border-color: var(--pecentageSub);
        }
    }
}

@include categories();


.group_resizer {
    background-color: var(--pecentageSub) !important;
    width: 10px !important;
    height: 10px !important;
    border-radius: 5px !important;
}

.drawing_group label {
    color: var(--mappingGroupTitle);
    position: absolute;
    left: 0;
    top: -25px;
    font-size: medium;
}

.react-flow__node-toolbar i {
    color: var(--pecentageSub);
    font-size: medium;
}

.drawing-save-button {
    position: fixed;
    right: 100px;
    bottom: 10px;
    background-color: var(--mappingBottomButton) !important;
}

.drawing-calculate-button {
    position: fixed;
    right: 10px;
    bottom: 10px;
    background-color: var(--mappingBottomButton) !important;
}

.drawing-copy-button {
    position: fixed;
    right: 163px;
    bottom: 10px;
    background-color: var(--mappingBottomButton) !important;
}

.validation-box {
    display: flex;
    place-content: center;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 10px;

    .message {
        width: 50% !important;
    }
}

.loading-calculation {
    width: 250px !important;
    height: 250px !important;
}