.backgroundLoginMain {
  align-items: center !important;
  padding: 70px !important;
  margin-bottom: 10px !important;
  margin: 0px !important;
  margin-top: 20px !important;
  max-width: 45%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  min-height: 70px;
  background: #ffffff;
}

.welcomeTxt {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: var(--mainColor)
}

.secondaryTxt {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--mainTextInptColor);
}

.mainContaa {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 50px !important;
  // max-width: 45%;
}

.customBtnn {
  width: 100%;
}

.forgotTxt {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  text-decoration-line: underline;
  color: #2684ff;
  float: right;
  cursor: pointer;
}
