.imageMain {
  height: 50px !important;
  margin-bottom: 20px;
}

.documentUploaderMain {
  margin-bottom: 20px;
  flex-direction: column;
  margin-top: -5px !important;
}

.file-preview {
  margin: 0 10px;
}

.labelUploder {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  color: var(--mainTextInptColor);
  margin-bottom: 5px !important;
}

.image {
  margin-right: 5px;
}

.custom-document-upload {
  border: 1px solid var(--mainColor);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #ffff;
  color: var(--mainColor);
  font-weight:800 !important;
  font-size: 14px;
  width: 100%;
  text-align: center;
  padding: 13px 12px;
  cursor: pointer;
  border-radius: 5px;
}