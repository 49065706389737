.titleViewMain {
  border-bottom: 1px solid var(--mainColor);
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 5px;
  margin-right: 1rem !important;
}

.textTitle {
  font-family: "ProximaNovaBold" !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: var(--mainColor);
}
