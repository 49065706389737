.pagination.justify-content-center {
  --pagination-justify-content-fb: left !important;
}

.paginationMain {
  max-width: 400px !important;
  min-height: 2.71428571em;
  .ui.pagination.menu .active.item {
    border-top: none;
    padding-top: .92857143em;
    background-color: var(--mainColor);
    color: rgba(0, 0, 0, .95);
    box-shadow: none;
    color: #fff;
  }
}

.pagination .page-link {
  color: var(--pagination-color, var(--mainColor));
}

.pagination .active>.page-link {
  z-index: 3;
  color: var(--pagination-active-color, var(--pagination-bg, #fff));
  background-color: var(--pagination-active-bg,
      var(--pagination-color, var(--mainColor)));
  border-color: var(--pagination-active-border-color,
      var(--pagination-active-bg, var(--pagination-color, var(--mainColor))));
}

.paginate_div {
  text-align: end;
  align-items: center;
  .ui.selection.dropdown {
    min-height: 24px !important;
    height: 32px;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 8px;
  }
  .ui.dropdown>.text {
    font-size: 0.78571429rem !important;
}
}

.pageDetailsView {
  padding-left: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}


.paginate_div .ui.selection.dropdown>.dropdown.icon {
  padding: 0.90666667em !important;
  top: 0.69em !important;
}

.nextPreviousIcon{
  color: var(--mainColor);
}

.nextPreviousMainView{
  display: flex !important;
  align-items: center !important;
}

.paginationContent{
  font-size: 14px !important;
}