.switchMain {
  display: flex;
  flex-direction: column;
  .active {
    background-color: var(--mainColor);
    color: #fff;
    font-family: "ProximaNova";
    font-size: 12px;
    line-height: 12px;
    font-weight: 600 !important;
    border: 1px solid var(--mainColor);
  }
}

.label {
  font-family: "ProximaNova";
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  color: var(--mainTextInptColor);
  font-weight: 400 !important;
}

.inactive {
  background-color: #fff;
  color: #c1c7d0;
  font-family: "ProximaNova";
  font-size: 12px;
  line-height: 12px;
  font-weight: 600 !important;
  border: 1px solid var(--mainColor);
}

.buttonCustomSwitch {
  padding: 0px !important;
}
