.impactCard {
  align-items: center;
  padding: 16px;
  margin-bottom: 10px !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  min-height: 70px;
  background: #ffffff;
  border: 1px solid #71A950;
}

.simulationModal {
  left: 10% !important;
  margin: 0px !important;
  width: 90% !important;
  height: 100%;
  background: #ffffff !important;
}

.modal-content {
  width: 100% !important;
  overflow-x: hidden !important;

  .modal-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #535D6D;
  }

  .color-black {
    color: #212121 !important;
  }

  .button-icon {
    padding: 10px 10px 12px 10px !important;
  }

  .add-button {
    padding: 10px !important;
  }

  .button-icon-view {
    padding-top: 5px;
  }
}

.modal-bottom {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.simulation-modal {
  top: 20% !important;
  height: 30% !important;

  .modal-header {
    color: #71A950 !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
  }

  .modal-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #212121 !important;
  }

  .modal-description {
    .currentImpactTitle {
      text-align: center !important;
    }

    .currentImpact {
      text-align: center !important;
    }

    .simulatedImpactTitle {
      font-family: 'Proxima Nova', sans-serif;
      font-size: 10px;
      font-weight: 600;
      line-height: 10px;
      text-align: left;
      color: #71A950;
      text-align: center !important;
    }

    .simulatedImpact {
      font-family: 'Proxima Nova', sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 18px;
      text-align: left;
      color: #535D6D;
      text-align: center !important;
    }
  }

  .simulation-input {
    input {
      border-radius: 5px 0 0 5px;
    }
  }

  .save-button {
    border-radius: 0 5px 5px 0 !important;
    background: #71A950;
    padding-bottom: 13px !important;
  }
}