.selectBoxContent {
  margin-top: 0px;
  margin-bottom: 25px;
  .ui.card > .content > .header + .description,
  .ui.card > .content > .meta + .description,
  .ui.cards > .card > .content > .header + .description,
  .ui.checkbox input.hidden + label {
    font-family: "ProximaNova" !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    background: #fff;
    color: #707070;
  }
}

.newSupplierBottomButton{
  width: 100% !important;
}

.newSupplierBottomUserAdd{
  width: max-content !important;
}

.ingredientTableView {
  margin-top: 20px !important;
}

.customIngredientUnitButton {
  margin-top: 24px !important;
}

.customGridColumnEBN {
  margin-bottom: 0px !important;
}

.customSelectText {
  font-size: medium;
  // margin-left: -13px;
}

.customGridColumn {
  padding-top: 0px !important;
}

.customContainer {
  border: 1px solid rgb(176, 176, 176);
  margin-left: 0;
  margin-right: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 5px;
}

.customGridColomnTyp {
  margin-top: 5px !important;
}

.saveAndContBtn {
  margin-top: 15px;
}

.ingredientRegisterUnitButton {
  text-align: start;
}

.customTileViewHeader{
  margin-top: 20px;
}

.packagingTitleText {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: var(--mainColor);
}