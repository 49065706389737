.removeInputPadding {
  padding-bottom: 5px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
}

.customSupplierUpdate {
  margin-right: 20px !important;
}

.supplierEditMainButton {
  display: flex !important;
  margin-top: 30px !important;
}

.facilityViewEnergyTitle {
  margin-top: 25px !important;
}
