.packaging-machinery-view {
  overflow-x: auto !important;
  overflow-y: hidden !important;
  padding-top: 45px;
}

.packaging-machinery-view-edit {
  overflow-x: auto !important;
  overflow-y: hidden !important;
  min-height: 400px !important;

  .dropDownMain {
    .transition {
      max-height: 130px !important;
    }
  }
}