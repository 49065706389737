.backgroundUnauthorized {
    align-items: center !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    background: #ffffff;
    display: flex !important;
    justify-content: center;
    align-items: center !important;
    text-align: center !important;

    .ui.accordion,
    .ui.accordion .accordion {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        padding: 20px;
    }
}

.subUnauthorized {
    text-align: center;
    font-size: 15px;
}

.unauthorizedText {
    text-align: center;
    font-size: 24px;
    margin-bottom: 25px !important;
}